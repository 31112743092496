import { get, getSSR, getSwr, post, postSwr } from "@common/network/networkInterface"
import { getHashFromObject } from "@common/lib/util"
import {
  autoRefreshSWROptions,
  handleError,
  options,
  postFetcher,
  getFullUrl,
} from "@common/network/utils"
import { removeAllSlashes } from "lib/util"
import { getFiltersStateFromQueryString } from "lib/filters"

export function getBookmarks(shouldFetch) {
  const { data, isLoading, isError, mutate, message, status } = getSwr(
    `bookmarks`,
    shouldFetch
  )
  return {
    bookmarks: data,
    isBookmarksLoading: isLoading,
    isBookmarksError: isError,
    mutate,
    message,
    status,
  }
}

export const downloadReport = async (apiUrl, details, filename, onResponse) => {
  // special case for pptx, this needs to be handled by next server
  const url = apiUrl?.includes("api/") ? `/${apiUrl}` : getFullUrl(apiUrl)
  const detailsStr = JSON.stringify({
    ...details,
    query: details.query || "",
  })

  try {
    fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: detailsStr,
    })
      .then(function (resp) {
        return resp.blob()
      })
      .then(function (blob) {
        //return download(blob, "CUSTOM_NAME.pdf")
        onResponse()
        const href = window.URL.createObjectURL(blob)
        const link = document.createElement("a")
        link.href = href
        link.setAttribute("download", filename || "download.csv") //or any other extension
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
  } catch {
    alert("Could not download file. Please try again.")
    onResponse()
  }
}

export async function uploadFile(url, formData) {
  const fullUrl = getFullUrl(url)
  try {
    return await postFetcher(fullUrl, formData, {})
  } catch (error) {
    return handleError(error, `uploadFile ${url}`)
  }
}

export function getCatalogTask({ customerId }) {
  const url = `get_catalog_task_status/${customerId}`
  return getSwr(url)
}

export function getQuota() {
  const shouldFetch = true
  const { data, isLoading, isError, message, status } = getSwr(
    `get_quota`,
    shouldFetch
  )
  return {
    quota: data,
    isQuotaLoading: isLoading,
    isQuotaError: isError,
    message,
    status,
  }
}

export function getSources(customerId) {
  const shouldFetch = customerId ? true : false
  const { data, isLoading, isError, message, status } = getSwr(
    `get_sources/${customerId}`,
    shouldFetch
  )
  return {
    sources: data,
    isSourcesLoading: isLoading,
    isSourcesError: isError,
    message,
    status,
  }
}

export function getCategories(customerId) {
  const shouldFetch = customerId ? true : false
  const { data, isLoading, isError, message, status } = getSwr(
    `get_categories/${customerId}`,
    shouldFetch
  )
  return {
    categories: data,
    isCategoriesLoading: isLoading,
    isCategoriesError: isError,
    message,
    status,
  }
}

export const resendOtp = async () => {
  return await get(`resend_otp`)
}

export const verifyOtp = async (details) => {
  return await post(`verify_otp`, details)
}

export function getPermissions(shouldFetch = true) {
  const { data, isLoading, isError, mutate, message, status } = getSwr(
    `get_permission`,
    shouldFetch
  )

  return {
    permissions: data,
    isPermissionsLoading: isLoading,
    isPermissionsError: isError,
    mutate,
    message,
    status,
  }
}

// payments
export function getPaymentPlans(details, shouldFetch = true) {
  return postSwr(`get_plans_meta`, details, shouldFetch)
}

// account preferences
export const getAccountPreferences = (
  customerId,
  shareableUrlId,
  shouldFetch = true
) => {
  const fetch = (!!customerId || !!shareableUrlId) && shouldFetch == true
  if (!fetch) return Promise.reject({ error: "Customer not found" })
  let apiUrl = `get_account_prefs/${customerId || shareableUrlId}`
  apiUrl = shareableUrlId ? apiUrl + `?shareable_url_id=${shareableUrlId}` : apiUrl
  return get(apiUrl)
}

// customer preferences
export const getCustomerPreferences = (customerId, shareableUrlId) => {
  let apiUrl = `get_customer_prefs/${customerId ?? shareableUrlId}`
  apiUrl = shareableUrlId ? apiUrl + `?shareable_url_id=${shareableUrlId}` : apiUrl
  return get(apiUrl)
}

export const updateCustomerPreferences = async (customerId, details) => {
  return await post(`update_customer_prefs/${customerId}`, details)
}

export const updateAccountPreferences = async (customerId, details) => {
  return await post(`update_account_prefs/${customerId}`, details)
}

// TODO - support shareableUrlId before using
export function getAccountPreferencesSSR(cookie, customerId) {
  const apiUrl = `get_account_prefs/${customerId}`
  return getSSR(cookie, apiUrl)
}

/* --------Entity List ----------- */

// send both ids and catId
export function fetchEntity(
  customerId,
  ids,
  catId,
  shouldFetch = true,
  shouldMutate = false,
  demo,
  shareableUrlId
) {
  const fetch =
    customerId != undefined &&
    ids != undefined &&
    catId != undefined &&
    shouldFetch == true
  const { data, isLoading, isError } = fetchEntityList({
    customerId,
    ids,
    catId,
    shouldFetch: fetch,
    shouldMutate,
    demo,
    shareableUrlId,
  })

  return {
    entity: data ? Object.values(data)[0] : {},
    isLoading: isLoading,
    isError: isError,
  }
}

// type -> Optional. e.g  type = entities, manual
// cat_id / ids -> both have to be provided. only 1 won't work.
// expand=true -> Used to get the components of a group. (in manage -> groups -> edit group flow)
/**
 *
 * @param {{
 *   customerId?: string | string[]
 *   ids?: string |  string[]
 *   catId?: string |  string[]
 *   sourceId?: string
 *   reportId?: string
 *   count?: number
 *   type?: string
 *   text?: string
 *   shareableUrlId?: string
 *   shouldFetch?: boolean
 *   shouldMutate?: boolean
 *   expand?: boolean
 *   demo?: boolean
 *   start?: number,
 * }}
 * @returns
 */
export function fetchEntityList({
  customerId,
  ids,
  catId,
  sourceId,
  reportId,
  count = 100,
  text,
  type,
  shouldFetch = true,
  shouldMutate = false,
  expand = false,
  demo,
  start = 0,
  shareableUrlId,
}) {
  const encodedText = text ? encodeURIComponent(removeAllSlashes(text)) : null

  const apiUrl = getApiURL({
    customerId,
    type,
    ids,
    catId,
    text: encodedText,
    count,
    sourceId,
    reportId,
    expand,
    demo,
    start,
    shareableUrlId,
  })

  const fetch = (!!customerId || !!shareableUrlId) && shouldFetch == true

  const { data, isLoading, isError, message } = getSwr(
    apiUrl,
    fetch,
    null,
    shouldMutate
  ) //{revalidateOnMount: true})

  return {
    data: data,
    isLoading,
    isError,
    message: message,
  }
}

// same as fetchEntityList without SWR
export async function getEntityList({
  customerId,
  ids,
  catId,
  sourceId,
  reportId,
  text,
  type,
  expand = false,
  count = 100,
  start = 0,
}) {
  const apiUrl = getApiURL({
    customerId,
    type,
    ids,
    catId,
    text,
    sourceId,
    reportId,
    expand,
    // count,
    start,
  })
  const { data, status, message } = await post(
    "list",
    getFiltersStateFromQueryString(apiUrl)
  )
  return {
    data,
    message,
    status,
  }
}

export function getApiURL({
  customerId,
  type,
  ids,
  catId,
  text,
  sourceId,
  reportId,
  expand,
  demo,
  count,
  start,
  shareableUrlId,
}) {
  let apiUrl = `list/${customerId}/`
  apiUrl = type ? apiUrl + `&type=${type}` : apiUrl
  apiUrl = ids ? apiUrl + `&ids=${ids}` : apiUrl
  apiUrl = catId ? apiUrl + `&cat_id=${catId}` : apiUrl
  apiUrl = text ? apiUrl + `&text=${text}` : apiUrl
  apiUrl = sourceId ? apiUrl + `&source_id=${sourceId}` : apiUrl
  apiUrl = reportId ? apiUrl + `&report_id=${reportId}` : apiUrl
  apiUrl = expand == true ? apiUrl + `&expand=true` : apiUrl
  apiUrl = demo ? apiUrl + `&demo=true` : apiUrl

  apiUrl = count ? apiUrl + `&count=${count}` : apiUrl
  apiUrl = count ? apiUrl + `&start=${start}` : apiUrl
  apiUrl = shareableUrlId ? apiUrl + `?shareable_url_id=${shareableUrlId}` : apiUrl
  return apiUrl
}

// tasks
export const createUserTask = async (details) => {
  return post(`data_source/user_task`, details)
}

export const deleteUserTask = async (details) => {
  return post(`delete_user_task`, details)
}

export const refreshUserTask = async (details) => {
  return post(`data_source/user_task/recrawl`, details)
}

export function reCrawlEids(details) {
  return post(`data_source/eids/recrawl`, details)
}

export function useReCrawlStatus(details, shouldFetch) {
  return postSwr(`data_source/eids/recrawl/status`, details, shouldFetch)
}

// forceFetch true -> fetches once. Else fetches every 10 seconds
export function getUserTasksSwr(
  customerId,
  searchQuery,
  pageSize,
  start,
  shouldFetch,
  forceFetch = false
) {
  let apiUrl = `get_user_tasks/${customerId}`
  if (pageSize) apiUrl += `?count=${pageSize}`
  if (start != null)
    apiUrl += `${apiUrl.includes("?") ? `&start=${start}` : `?start=${start}`}`
  if (searchQuery) apiUrl += `&text=${searchQuery}`

  const extraOptions = forceFetch == true ? options : autoRefreshSWROptions
  return getSwr(apiUrl, shouldFetch, null, forceFetch, extraOptions)
}

export function getUserTasks(customerId) {
  const apiUrl = `get_user_tasks/${customerId}`
  return get(apiUrl)
}

export function getUserTasksDetails(customerId, userTaskId, shouldFetch) {
  const apiUrl = `get_user_tasks/${customerId}?utid=${userTaskId}`
  return getSwr(apiUrl, shouldFetch)
}

//CREATES BOOKMARK
export const createOrUpdateBookmark = async (details) => {
  return post(`update_bookmarks`, details)
}

//DELETES BOOKMARK
export const deleteBookmark = async (bookmark_id) => {
  return get(`delete_bookmark/${bookmark_id}`)
}
// group mangement
export function deleteTag(customerId, categoryId, tagId, shouldDelete) {
  const shouldFetch =
    customerId && categoryId && tagId && shouldDelete == true ? true : false
  return getSwr(`delete_tag/${customerId}/${categoryId}/${tagId}`, shouldFetch)
}
export const updateTag = async (details) => {
  return post(`update_tag`, details)
}

/**
 *
 * TOPIC
 */

// GET SYSTEM TOPICS

export const getSystemTopics = (customerId, categoryId) => {
  const apiUrl = `get_system_topics/${customerId}/${categoryId}`
  const shouldFetch = customerId && categoryId
  return getSwr(apiUrl, shouldFetch)
}

// GET USER DEFINED TOPICS
export const getUserDefinedTopics = (customerId, categoryId) => {
  const apiUrl = `get_user_defined_topics/${customerId}/${categoryId}`
  const shouldFetch = !!customerId && !!categoryId
  return getSwr(apiUrl, shouldFetch)
}

// UPDATE SYSTEM TOPIC
export const updateSystemTopics = async (customerId, categoryId, details) => {
  const apiUrl = `update_system_topic/${customerId}/${categoryId}`
  return post(apiUrl, details)
}

// CREATE OR UPDATE TOPIC
export const updateUserDefinedTopic = async (customerId, categoryId, details) => {
  const apiUrl = `update_user_defined_topic/${customerId}/${categoryId}`
  return post(apiUrl, details)
}

export const deleteUserDefinedTopic = async (customerId, categoryId, topicId) => {
  const apiUrl = `delete_user_defined_topic/${customerId}/${categoryId}/${topicId}`
  return get(apiUrl)
}

// serves as an eg. of how to use postSwr with mutate
export function updateTagSwr(details, shouldFetch, shouldMutate) {
  const { data, isLoading, isError, message, status } = postSwr(
    `update_tag`,
    details,
    shouldFetch,
    shouldMutate,
    false
  )
  return {
    updateData: data,
    isUpdateLoading: isLoading,
    isUpdateError: isError,
    message,
    status,
  }
}

// responses
export function updateCustomerTodo(customerId, details) {
  return post(`update_cust_todo/${customerId}`, details)
}

export function inviteUsers(details) {
  const apiUrl = "invite_users"
  return post(apiUrl, details)
}
export function getTeamAccounts() {
  return getSwr("get_team_accounts")
}

export function getTeams(shouldFetch) {
  return getSwr("get_teams", shouldFetch)
}

export function updateTeam(details) {
  const apiUrl = "update_team"
  return post(apiUrl, details)
}

export function updateUserResource(details) {
  const apiUrl = `resource/update_user`
  return post(apiUrl, details)
}

export function deleteUser(account_id) {
  const apiUrl = `resource/delete_user`
  return post(apiUrl, { account_id })
}
export function deleteTeam(teamId) {
  const apiUrl = `teams/delete_team/${teamId}`
  return get(apiUrl)
}
// account login/signup/isLoggedIn
export const logout = async () => {
  const apiUrl = `logout`
  const url = getFullUrl(apiUrl)
  try {
    const response = await fetch(url)
    const resCode = response.status
    const success = resCode == 200 ? true : false
    if (resCode == 200)
      return {
        success: success,
        isError: null,
      }
  } catch (error) {
    return {
      success: false,
      isError: JSON.parse(JSON.stringify(error)),
    }
  }
}

export const getLogin = async (details) => {
  return post(`login`, details)
}

export const getQuickLogin = async (details) => {
  return post(`login/magic_link`, details)
}

//? ******* DO NOT USE GET, FETCHER HERE ,THIS IS INVOKED IN SERVER SIDE SO WE DON'T WANT TO REDIRECT *******
export const getIsLoggedIn = async (cookie, query) => {
  const apiUrl = `is_logged_in`
  let url = getFullUrl(apiUrl)
  console.log("url", url)
  if (query) url += `?${query}`
  try {
    const response = await fetch(url, cookie)
    const data = await response.json()
    const resCode = response.status
    return {
      data: data,
      resCode: resCode,
      isError: null,
    }
  } catch (error) {
    return {
      data: null,
      isError: JSON.parse(JSON.stringify(error)),
    }
  }
}

export const useIsLoggedIn = (publicId) => {
  const apiUrl = `is_logged_in?public_id=${publicId}`
  return getSwr(apiUrl)
}

export const getSignup = async (details) => {
  return post(`register`, details)
}

export const getResetPassword = async (details) => {
  return post(`update_password`, details)
}

export const getResetPasswordToken = async (details) => {
  return post(`reset_pass_send_token`, details)
}
/* ONTOLOGY */
export function getOntologies(customerId) {
  const shouldFetch = customerId ? true : false
  const { data, isLoading, isError, message, status } = getSwr(
    `get_ontologies/${customerId}`,
    shouldFetch
  )
  return {
    ontologies: data,
    isOntologiesLoading: isLoading,
    isOntologiesError: isError,
    message,
    status,
  }
}

/* COMMON */
// TODO - move to network/common.js

// TODO - convert all repeated get/post calls to use these common functions
// apiUrl e.g `update_account_prefs/${customerId}`

export async function gLogin(details) {
  return post(`google_oauth_redirect`, details)
}

// <---- FOR TRI MODE START ---->

export function getCategoryList() {
  return get(
    `https://storage.googleapis.com/thereviewindex-generalindex-views/categories.json`,
    true
  )
}

export function getListsList() {
  return get(
    `https://storage.googleapis.com/thereviewindex-generalindex-views/lists.json`,
    true
  )
}
// <---- FOR TRI MODE END ---->

/**
 *
 * *********************UPLOAD CSV******************
 */
export function useLabels(shouldFetch) {
  return getSwr(`get_annotated_labels`, shouldFetch)
}

export function updateCustomerLabel(details) {
  const apiUrl = `update_annotated_labels`
  return post(apiUrl, details)
}

export function validateCSV(details) {
  const apiUrl = `data_source/validate_csv`
  return post(apiUrl, details)
}

/**
 *
 *********************** REPORT ***************
 */

/**
 * @param {Object} [options] - Options for retrieving reports.
 * @param {string} [options.reportId] - Unique identifier for the report.
 * @param {string} [options.categoryId] - Category identifier for the report.
 * @param {string} [options.customerId] - Category identifier for the report.
 * @param {string} [options.entityId] - Entity identifier associated with the report.
 * @param {'system_report' | 'user_report'} [options.reportType] - Type of the report.
 * @param {'available' | 'showcase'} [options.reportDefClass] - Classification of the report.
 * @param {'template' | 'instance'} [options.reportMode] - Mode of the report.
 * @param {boolean} [options.shouldFetch] - Whether to fetch the reports.
 * @returns
 */

export function getReports({
  reportId,
  categoryId,
  entityId,
  reportType, //system_report or user_report
  reportDefClass, //available or showcase
  reportMode, // template or instance
  shouldFetch,
  shareableUrlId,
  returnWidgetConfigs,
} = {}) {
  let apiUrl = `reports`
  const params = new URLSearchParams()

  // Append parameters if they exist
  if (categoryId && entityId) {
    params.append("cat_id", categoryId)
    params.append("entity_id", entityId)
  }
  if (reportId) params.append("report_id", reportId)
  if (reportType) params.append("report_type", reportType)
  if (reportDefClass) params.append("report_def_class", reportDefClass)
  if (reportMode) params.append("report_mode", reportMode)
  if (shareableUrlId) params.append("shareable_url_id", shareableUrlId)
  if (returnWidgetConfigs)
    params.append("return_widget_configs", returnWidgetConfigs)

  // Only append the query string if there are parameters
  if (Array.from(params).length > 0) {
    apiUrl += `?${params}`
  }

  return getSwr(apiUrl, shouldFetch)
}

export function getReport(reportId, shareableUrlId, returnWidgetConfigs) {
  const shouldFetch = !!reportId
  return getReports({ reportId, shouldFetch, shareableUrlId, returnWidgetConfigs })
}

export function createReport(details) {
  const apiUrl = `reports/create_report`
  return post(apiUrl, details)
}

export function deleteReport(reportId) {
  const apiUrl = `reports/delete_report?report_id=${reportId}`
  return get(apiUrl)
}

export function updateReport(details) {
  const apiUrl = `reports/update_report`
  return post(apiUrl, details)
}

export function generateShareAbleUrl(details) {
  return post("generate_shareable_url", details)
}

export function revokeShareableUrl(shareableUrlId) {
  return get(`revoke_shareable_url/${shareableUrlId}`)
}

export function useGetShareableUrl(shareableUrlId) {
  const apiUrl = `get_shareable_url/${shareableUrlId}`
  return getSwr(apiUrl, !!shareableUrlId)
}

export function useGetAllShareableUrls(shouldFetch) {
  return getSwr("get_all_shareable_urls", shouldFetch)
}
/**
 *
 *********************** WIDGET ***************
 */

/**
 *
 * @param {{
 *  widgetIds?: Array<string>
 *  isEditable?: boolean
 *  widgetTypes?: "user_widget" | "system_widget"
 *  shouldFetch?: boolean
 *  shareableUrlId?: string
 * }}
 *
 * @returns
 */
export function useFetchWidgets({
  widgetIds = [],
  isEditable = true,
  widgetTypes = ["user_widget"],
  shareableUrlId,
  shouldFetch,
} = {}) {
  const ids = widgetIds.join(",")
  const widget_types = widgetTypes.map((item) => `widget_types=${item}`).join("&")
  let apiUrl = `get_widgets?only_editable=${isEditable}`
  if (shareableUrlId) apiUrl += `&shareable_url_id=${shareableUrlId}`
  if (ids) apiUrl += `&widget_ids=${ids}`
  if (widgetTypes?.length) apiUrl += `&${widget_types}`
  return getUpdatedWidgetResponse(getSwr(apiUrl, shouldFetch), widgetIds)
}

export function getWidget(widgetId, isEditable = true) {
  const apiUrl = `get_widgets?widget_ids=${widgetId}&only_editable=${isEditable}`
  return getUpdatedWidgetResponse(get(apiUrl), [widgetId])
}

export function useFetchWidget(widgetId, isEditable = true, shouldFetch, key) {
  let apiUrl = `get_widgets?widget_ids=${widgetId}&only_editable=${isEditable}`
  if (key) apiUrl = apiUrl + `&fe_cache_key=${key}`
  return getUpdatedWidgetResponse(getSwr(apiUrl, shouldFetch), [widgetId])
}

/* moves graph_props into state as graph_state */
function getUpdatedWidgetResponse(res, widgetIds) {
  const updatedData = res?.data
  widgetIds.forEach((widgetId) => {
    const widgetData = updatedData?.[widgetId]
    if (widgetData?.config?.graph_props) {
      const { config } = widgetData
      config.state = { graph_state: config.graph_props, ...config.state }
      delete config.graph_props
    }
  })

  return { ...res, data: updatedData }
}

export function updateWidget(details = {}) {
  const apiUrl = "update_widget"
  return post(apiUrl, details)
}

export function deleteWidget(widgetId) {
  const apiUrl = `delete_widget/${widgetId}`
  return get(apiUrl)
}

export function useFetchMetricsDims(
  details = {},
  shouldFetch = false,
  shareableUrlId
) {
  const detailsHash = getHashFromObject(details)
  let apiUrl = `get_metrics_dims/${detailsHash}`
  if (shareableUrlId) apiUrl += `?shareable_url_id=${shareableUrlId}`
  return postSwr(apiUrl, details, shouldFetch)
}

export function updateRecord(details, cat_id) {
  return post(`${cat_id}/records`, details)
}

/**************** PHRASES GENERATION**********************/

export function generatePhrases(details) {
  return post(`user_topics/generate_suggested_phrases`, details)
}

export function getPreviewPhrases(details) {
  return post(`user_topics/phrases/preview`, details)
}

export function generateSimilarPhrases(details) {
  return post("user_topics/generate_similar_phrases", details)
}

export function useGetReleaseNotes() {
  return getSwr("get_release_notes")
}
