import { isNullOrEmpty, isObject, isUndefinedOrEmpty } from "@common/lib/util"

/**
 * Checks if all values in the object are undefined.
 *
 * @param  obj - Object to check.
 * @returns - True if all values are undefined, false otherwise.
 */
export const areAllValuesUndefined = (obj: Record<string, any>): boolean =>
  Object.values(obj).every((value) => value === undefined)

/**
 * Filters an object by a list of keys.
 *
 * This function accepts an object and a list of keys, and returns a new object
 * that includes only the properties of the original object that are specified
 * in the list of keys. If a key in the list does not exist in the original object, it is ignored.
 *
 * @param  obj - The original object to filter, or null/undefined.
 * @param  keys - An array of keys to retain in the filtered object. Must be an array.
 * @returns  A new object containing only the keys specified in `keys`, or an empty object if `obj` is null/undefined or `keys` is not an array.
 */
export function filterObjectByKeys(
  obj: Record<string, any> | null | undefined,
  keys: Array<string>
): Record<string, any> {
  if (
    typeof obj !== "object" ||
    obj === null ||
    isNullOrEmpty(keys) ||
    !Array.isArray(keys)
  ) {
    return obj || {}
  }

  const filteredObj: Record<string, any> = {}
  keys.forEach((key) => {
    if (Object.hasOwnProperty.call(obj, key)) {
      filteredObj[key] = obj[key]
    }
  })
  return filteredObj
}

/**
 * Replaces or adds a value for a specific key within a given object.
 * If the specified key already exists in the object, its value is updated with the new value.
 * If the key does not exist, it is added to the object with the given value.
 * If the input is invalid, the original object is returned without any modifications.
 *
 * @param obj - The target object where the value should be replaced or added. Must be a non-null object.
 * @param  key - The key for which the value should be replaced or added. Keys are case-sensitive.
 * @param  newValue - The new value to assign to the key in the object. This value can be of any type, including string, number, boolean, object, or array.
 *
 * @returns - Returns the modified object if the parameters are valid, otherwise returns the original object.
 */
export function replaceValueInObject(
  obj: Record<string, any>,
  key: string,
  newValue: any
): Record<string, any> {
  if (!obj || typeof obj !== "object" || obj === null || !key) {
    return obj
  }
  if (isNullOrEmpty(newValue)) delete obj[key]
  else obj[key] = newValue
  return obj
}

/**
 * Recursively changes the keys of an object based on a provided mapping.
 * It supports nested objects and arrays, ensuring that the structure of the
 * original object is preserved while keys are updated as specified.
 *
 * @param  obj - The original object (or array) whose keys are to be changed.
 *                             Nested objects and arrays are supported.
 * @param  keyMap - An object representing the mapping of old keys to new keys.
 *                          Each key in this object corresponds to a key in the original object that needs to be changed,
 *                          and its value is the new key name.
 * @returns - A new object (or array) with the keys changed as specified by the keyMap.
 *                           The original object is not modified.
 *
 * @example
 * const originalObject = {
 *   name: "John Doe",
 *   age: 30,
 *   address: {
 *     street: "123 Main St",
 *     city: "Anytown",
 *     country: "Anycountry",
 *   },
 * };
 * const keyMap = {
 *  name: "fullName",
 *  street: "streetAddress",
 *  country: "countryName"
 *};
 */
export function changeKeys(
  obj: Record<string, any> | any[],
  keyMap: Record<string, string>
) {
  if (
    typeof obj !== "object" ||
    obj === null ||
    typeof keyMap !== "object" ||
    keyMap === null
  )
    return obj

  const newObj = Array.isArray(obj) ? [] : {}

  Object.keys(obj).forEach((key) => {
    const newKey = keyMap[key] || key
    //@ts-expect-error  key can be field or array index
    const value = obj[key]
    if (typeof value === "object" && value !== null) {
      //@ts-expect-error  key can be field or array index
      newObj[newKey] = changeKeys(value, keyMap)
    } else {
      //@ts-expect-error  key can be field or array index
      newObj[newKey] = value
    }
  })

  return newObj
}

/**
 * Checks if two values are strictly equal, with special handling for 0 and NaN.
 *
 */
function is(x: unknown, y: unknown): boolean {
  if (x === y) {
    return x !== 0 || y !== 0 || 1 / x === 1 / y
  } else {
    return x !== x && y !== y
  }
}

/**
 * Performs a shallow comparison of two objects.
 *
 * @param {Record<string, any>} objA - First object.
 * @param {Record<string, any>} objB - Second object.
 * @returns {boolean} - True if shallowly equal, false otherwise.
 */
export const shallowEqual = (
  objA: Record<string, any>,
  objB: Record<string, any>
): boolean => {
  if (is(objA, objB)) return true

  if (
    typeof objA !== "object" ||
    objA === null ||
    typeof objB !== "object" ||
    objB === null
  ) {
    return false
  }

  const keysA = Object.keys(objA)
  const keysB = Object.keys(objB)

  if (keysA.length !== keysB.length) return false

  for (let i = 0; i < keysA.length; i++) {
    if (
      !Object.prototype.hasOwnProperty.call(objB, keysA[i]) ||
      !is(objA[keysA[i]], objB[keysA[i]])
    ) {
      return false
    }
  }

  return true
}

/**
 * Removes a key from an object.
 *
 * @param {Record<string, any>} target - Target object.
 * @param {string} path - Key to remove.
 * @returns {Record<string, any>} - New object with the key removed.
 */
const removeKey = (target: Record<string, any>, path: string): Record<string, any> =>
  Object.keys(target).reduce((acc, key) => {
    if (key !== path) {
      return Object.assign({}, acc, { [key]: target[key] })
    }
    return acc
  }, {})

const isEmpty = (obj: any) =>
  obj instanceof Date
    ? false
    : obj === "" || obj === null || obj === undefined || shallowEqual(obj, {})

export const removeEmpty = (object: any) =>
  Object.keys(object).reduce((acc, key) => {
    let child = object[key]

    if (isObject(object[key])) {
      child = removeEmpty(object[key])
    }

    return isEmpty(child) ? acc : { ...acc, [key]: child }
  }, {})

/**
 * Recursively removes a nested key from an object.
 *
 * @param {Record<string, any>} target - Target object.
 * @param {string} path - Dot-separated path of the key to remove.
 * @returns {Record<string, any>} - New object with the key removed.
 */
export const deepRemoveKey = (
  target: Record<string, any>,
  path: string
): Record<string, any> => {
  const paths = path.split(".")

  if (paths.length === 1) {
    return removeKey(target, path)
  }

  const [deepKey] = paths
  if (target[deepKey] === undefined) {
    return target
  }
  const deep = deepRemoveKey(target[deepKey], paths.slice(1).join("."))

  if (Object.keys(deep).length === 0) {
    return removeKey(target, deepKey)
  }

  return Object.assign({}, target, { [deepKey]: deep })
}

// Returns the delta between the two objects
export function getObjectDifference(obj1: any, obj2: any) {
  const diff: any = {}
  function compareObjects(obj1: any, obj2: any, path: string) {
    for (const key in obj1) {
      if (obj1.hasOwnProperty(key)) {
        const newPath = (path ? path + "." : "") + key
        if (!obj2.hasOwnProperty(key)) {
          // Key exists in obj1 but not obj2
          diff[newPath] = {
            obj1: obj1[key],
            obj2: undefined,
          }
        } else if (typeof obj1[key] === "object" && typeof obj2[key] === "object") {
          // Both values are objects, recursively compare
          compareObjects(obj1[key], obj2[key], newPath)
        } else if (obj1[key] !== obj2[key]) {
          // Key exists in both objects but with different values
          diff[newPath] = {
            obj1: obj1[key],
            obj2: obj2[key],
          }
        }
      }
    }
    for (const key in obj2) {
      if (obj2.hasOwnProperty(key) && !obj1.hasOwnProperty(key)) {
        // Key exists in obj2 but not obj1
        const newPath = (path ? path + "." : "") + key
        diff[newPath] = {
          obj1: undefined,
          obj2: obj2[key],
        }
      }
    }
  }
  compareObjects(obj1, obj2, "")
  return diff
}

export function filterEmptyValues(obj: any, maxDepth = Infinity) {
  function recursiveFilter(value: any, currentDepth: number) {
    if (Array.isArray(value)) {
      const filteredArray: any = value
        .map((item: any) => recursiveFilter(item, currentDepth + 1))
        .filter((item) => !isUndefinedOrEmpty(item))
      return filteredArray.length ? filteredArray : undefined
    }

    if (isObject(value) && currentDepth < maxDepth) {
      const filteredObj: any = {}
      Object.entries(value).forEach(([key, val]) => {
        const filteredValue = recursiveFilter(val, currentDepth + 1)
        if (!isUndefinedOrEmpty(filteredValue)) {
          filteredObj[key] = filteredValue
        }
      })
      return Object.keys(filteredObj).length ? filteredObj : undefined
    }
    return isUndefinedOrEmpty(value) ? undefined : value
  }

  return recursiveFilter(obj, 0)
}
