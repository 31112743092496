import { useCallback, useState, useEffect, useRef } from "react"
import useSWRInfinite from "swr/infinite"
// import fetch from "node-fetch"
import {
  getFiltersForComments,
  isFilterInPath,
  replaceFilter,
  replaceFilterInQuerystring,
} from "lib/filters.js"
import { getObjectFromQueryOverride, removeAllSlashes } from "lib/util.js"
import { getFiltersStateFromQueryString } from "lib/filters"
import { getFullUrl, postFetcher } from "@common/network/utils"
import { getSwr, postSwr } from "@common/network/networkInterface"
import {
  isNullOrEmpty,
  deepCopyObject,
  getHashFromObject,
  isString,
} from "@common/lib/util"
import { changeKeys } from "@common/lib/object.utils"

/**
 *
 * @param {{
 *    categoryId?: string
 *    entityId?: string | string[]
 *    reportId?: string
 *    overrideQueryObject?: Record<string,any>
 *    demo?: boolean
 *    shareableUrlId?: string
 *    isTemplate?: boolean
 *
 * }} categoryId
 * @return {{
 *  filters: import("@common/filters/types").FiltersConfig
 *  isLoading: boolean
 *  isLoading: boolean,
 *  isError: any,
 *  mutate: ()=>Promise<void>,
 *  message: string | string[],
 *  status: import("@common/types").Status,
 * }}
 */
export function useFetchFilters({
  categoryId,
  entityId,
  reportId,
  overrideQueryObject,
  demo = false,
  shareableUrlId,
  isTemplate,
} = {}) {
  const catId = isTemplate === true ? categoryId : undefined
  const eId = isTemplate === true ? entityId : undefined
  const _reportId = isTemplate === false ? reportId : undefined

  let query = { demo: demo ? ["true"] : undefined }
  const details = {}

  // for instances, report id should be sent for instance only
  if (_reportId) details.report_id = _reportId
  // for templates
  else if (eId && catId)
    query = {
      ...query,
      ids: isString(eId) ? eId?.split(",") : eId,
      cat_id: isString(catId) ? catId?.split(",") : catId,
    }

  if (overrideQueryObject)
    // for drill down (which can happen both in templates or instances)
    query = {
      ...query,
      ...overrideQueryObject,
    }

  details.query = query

  const shouldFetch =
    !!(_reportId || (catId && eId) || overrideQueryObject) && !isNullOrEmpty(details)

  const apiUrl =
    "get_filters/filters" +
    (shareableUrlId ? `?shareable_url_id=${shareableUrlId}` : "")
  const { data, isLoading, isError, message, status, mutate } = postSwr(
    apiUrl,
    details,
    shouldFetch,
    false,
    true
  )

  // transforming returned object. Change keys -
  // checkboxes -> options.
  // option.name -> option.display
  const filters = changeKeys(data, { checkboxes: "options", name: "display" })

  Object.values(filters || {}).forEach((item) => {
    if (item.options) {
      Object.values(item.options).forEach((option) => {
        if ("count_percent" in option) {
          option.countPercent = option.count_percent
          delete option.count_percent
        }
      })
    }
  })

  return {
    filters,
    isLoading,
    isError,
    mutate,
    message,
    status,
  }
}

export function useFetchMetricData({
  customerId,
  queryString,
  details,
  shouldFetch = true,
  initialData,
  shareableUrlId,
}) {
  /* Original details object shouldn't be mutated.
   * This is because the query/query_override are being converted to objects, where as in widgets/compare, these are strings.
   * e.g fails in compare page
   * */
  const detailsCopy = deepCopyObject(details)

  const queryObject =
    typeof queryString === "string"
      ? getFiltersStateFromQueryString(queryString)
      : queryString

  // Temp Handling - Currently query_override can be string or object.
  // TODO - query_override should be an object everywhere.
  const queryOverrideObject = getObjectFromQueryOverride(details["query_override"])

  detailsCopy["query"] = queryObject
  detailsCopy["query_override"] = queryOverrideObject
  const widgetId = detailsCopy.widget_id
  const query_type = widgetId || detailsCopy.query_type
  let apiUrl = `query/${customerId || shareableUrlId}/${query_type}`
  if (shareableUrlId) apiUrl += `?shareable_url_id=${shareableUrlId}`

  const isFetch = shouldFetch == true /* && customerId != undefined */
  const swrOptions = {
    revalidateOnFocus: false,
    fallbackData: {
      data: initialData,
      status: initialData ? "CACHED" : undefined,
    },
  }
  const { data, isLoading, isError, isValidating, mutate, message, status } =
    postSwr(apiUrl, detailsCopy, isFetch, undefined, true, swrOptions)

  return {
    data,
    isLoading,
    isError,
    isValidating,
    mutate,
    message,
    status,
  }
}

export function fetchKeywordSuggestions({
  customerId,
  categoryId,
  queryString,
  textQueryOverride,
}) {
  const text = removeAllSlashes(textQueryOverride)
  queryString = textQueryOverride
    ? replaceFilterInQuerystring(queryString, "text", text)
    : queryString

  const queryObject = getFiltersStateFromQueryString(queryString)

  const details = {
    query: queryObject,
    query_type: "search_suggestions",
  }
  const apiUrl = `query/${customerId}/${categoryId}/search_suggestions`
  const shouldFetch = customerId && categoryId && !isNullOrEmpty(queryObject)
  const { data, isLoading, isError, message, status } = postSwr(
    apiUrl,
    details,
    !!shouldFetch,
    false,
    true
  )
  return {
    keywords: data,
    isKeywordsLoading: isLoading,
    isKeywordsError: isError,
    message,
    status,
  }
}
/**
 *
 * @param {string} textQueryOverride -> overrides the text query present in the url.
 * @param {boolean} useCommentFilters -> Used in the case of "topics creation - preview"
 * when filters other than commentsUnit specific filters shouldn't be sent to backend.
 *
 */
export function useFetchComments({
  queryType,
  customerId,
  categoryId,
  queryString,
  count,
  timeOverrideStr,
  isDarkMode,
  textQueryOverride,
  useCommentFilters,
  queryOverride,
  shareableUrlId,
}) {
  //UPDATING QUERY STRING
  let newQueryString = useCommentFilters
    ? getFiltersForComments(queryString, "all")
    : queryString

  newQueryString = textQueryOverride
    ? replaceFilterInQuerystring(newQueryString, "text", textQueryOverride)
    : newQueryString
  newQueryString += `&count=${count}`
  if (timeOverrideStr)
    newQueryString = replaceFilter(newQueryString, "time", timeOverrideStr)
  if (!isFilterInPath(newQueryString, "sort")) newQueryString += `&sort=sentiment`
  if (isDarkMode) newQueryString += `&graph_dark_mode=${isDarkMode}`

  // MAKE FILTERS STATE FROM QUERY STRING
  const queryObject = getFiltersStateFromQueryString(newQueryString)

  //MAKE PAYLOAD
  const details = {
    query: queryObject,
    query_type: queryType,
    ...(queryOverride ? { query_override: queryOverride } : {}),
  }

  const detailsHash = getHashFromObject(details)

  //CONSTRUCT URL
  let apiUrl = `query/${customerId}/${categoryId}/${queryType}_${detailsHash}`
  if (shareableUrlId) apiUrl += `?shareable_url_id=${shareableUrlId}`
  const url = getFullUrl(apiUrl)

  const fetcher = ({ url, index }) =>
    postFetcher(
      url,
      JSON.stringify({
        ...details,
        query: {
          ...details.query,
          start: [`${index * count}`],
        },
      })
    )

  // let shouldFetch = customerId && categoryId && queryString

  // TODO - Since comments has "Load more", it is not implemented as a true infinite scroll.
  // This could be done using the basic useSWR query
  // Either change this to useSWR or change the implementation to proper infinite scroll
  const { data, error, size, setSize } = useSWRInfinite(
    (index) => ({ url, index }),
    fetcher,
    { revalidateOnFocus: false, revalidateFirstPage: false }
  )

  const firstData = data?.[0]?.data
  const lastData = data?.[data.length - 1]?.data
  const lastRecords = lastData?.records

  const isError = error || lastData?.status == "ERROR"
  const isLoading = !error && !data
  const isLoadingMore =
    isLoading || (size > 0 && data && typeof data[size - 1]?.data === "undefined")
  const isEmpty = firstData?.length === 0 //|| firstData?.records?.length == 0
  const isEnd = isEmpty || lastRecords?.length < count || lastRecords?.length == 0

  /**
   * NOTE: "data" & "message" is being manipulated
   *       as custom fetchers (in networkInterface) manipulates data into standard output structure
   *       & that standard output structure is received here intead of
   *       data directly as it was earlier (before this change).
   *
   * TODO: move this to a custom pagination inplementation to get rid of the complex data structure
   */
  return {
    data: data?.map((page) => page.data)?.filter(Boolean),
    size: size,
    setSize: setSize,
    isLoading: isLoading,
    isLoadingMore: isLoadingMore,
    isEmpty: isEmpty,
    isEnd: isEnd,
    isError: isError,
    errorMessage: lastData?.error_message || "Could not fetch data.",
    message: data?.map((page) => page.message),
  }
}

export function useProducts(customerId) {
  const category = "all" // categoryId
  const apiUrl = `api/insights/get_product_id_meta/${customerId}/${category}`
  const url = getFullUrl(apiUrl)

  const { data, isError, isLoading, message, status } = getSwr(
    url,
    true,
    null,
    false
  )

  return {
    data,
    isLoading,
    isError,
    message,
    status,
  }
}

export function usePreviousValue(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const useWindowEvent = (event, callback, bool) => {
  useEffect(() => {
    window.addEventListener(event, callback, bool)
    return () => window.removeEventListener(event, callback, bool)
  }, [event, callback, bool])
}

export const useCustomDomEvent = (event, div, callback, bool) => {
  useEffect(() => {
    div.addEventListener(event, callback, bool)
    return () => div.removeEventListener(event, callback, bool)
  }, [event, div, callback])
}

export const useConfig = ({
  configName,
  fallbackConfigName,
  fallbackConfig, // actual config
}) => {
  const [finalConfigName, setFinalConfigName] = useState()
  const [config, setConfig] = useState()
  const [isLoading, setIsLoading] = useState(false)
  if (!configName) return
  const initConfig = async () => {
    setConfig(null)
    setFinalConfigName(null)
    setIsLoading(true)
    try {
      const data = await import(`../configs/${configName}.json`)
      setConfig(data["default"])
      setFinalConfigName(configName)
      setIsLoading(false)
    } catch (err) {
      try {
        const data = await import(`../configs/${fallbackConfigName}.json`)
        setConfig(data["default"])
        setFinalConfigName(fallbackConfigName)
        setIsLoading(false)
      } catch (err) {
        if (fallbackConfig) setConfig(fallbackConfig)
        setIsLoading(false)
      }
    }
  }

  useEffect(() => {
    initConfig()
  }, [configName, fallbackConfigName])

  return [config, isLoading, finalConfigName]
}

export async function initConfig(
  configName,
  setConfig,
  fallbackConfig,
  fallbackConfigName
) {
  try {
    const data = await import(`../configs/dashboard/${configName}.json`)
    setConfig(data["default"])
  } catch (err) {
    try {
      const data = await import(`../configs/dashboard/${fallbackConfigName}.json`)
      setConfig(data["default"])
    } catch (err) {
      if (fallbackConfig) setConfig(fallbackConfig)
    }
  }
}

// deprecate useIsMounted & useMounted. use from common/hooks
export function useIsMounted() {
  const isMounted = useRef(false)
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])
  return useCallback(() => isMounted.current, [])
}

export function useMounted() {
  const [mounted, setMounted] = useState(false)
  useEffect(() => setMounted(true), [])
  return mounted
}
