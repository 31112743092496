import tailwindConfig from "@common/dev/tailwind.config.cjs"
import { CircularProgressbar, buildStyles } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"
import resolveConfig from "tailwindcss/resolveConfig"

const fullConfig = resolveConfig(tailwindConfig)

// TODO - change component. Shouldn't have to use styles/colors. Should use theme.

export default function CircularProgressBar({ percentage, isDarkMode }) {
  if (percentage == null) return <div></div>
  return (
    <CircularProgressbar
      value={percentage}
      text={`${percentage}%`}
      styles={buildStyles({
        // Colors
        pathColor: fullConfig.theme.colors.blue["500"],
        textColor: fullConfig.theme.colors.blue["500"],
        trailColor: isDarkMode
          ? fullConfig.theme.colors.gray["800"]
          : fullConfig.theme.colors.gray["200"],
      })}
    />
  )
}
